<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <Spinner :spin="spin"/>
            <section class="header activity_question">
                <a class="icon iconButton third" @click.prevent="goBackTo">
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
                <span class="fL">{{ title }}</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section  class="main activity_question">
                <div class="flexV">
                    <span class="fXXL third">{{qnaCampaign.campaignName}}</span>
                    <span class="fM gray">{{qnaCampaign.departmentName}}</span>
                    <span 
                        v-if="isInitialized"
                        class="fM gray"
                    >
                        {{ tsToDatetime(qnaCampaign.campaignStartTime) }} -
                        {{ tsToDatetime(qnaCampaign.campaignEndTime) }}
                    </span>
                </div>
                <span class="fM">{{ qnaCampaign.campaignDesc }}</span>
                <ValidationObserver v-if="canParticipate" v-slot="{ invalid }">
                    <ol>
                        <div
                            v-for="(question, index) in questions"
                            :key="'question' + index"
                        >
                            <ValidationProvider
                                slim 
                                :rules="getValidationRules(question)" 
                                v-slot="{ valid }"
                            >
                                <li class="fXXL primary" id="question-title">{{question.title}}</li>
                                <span class="fM gray" id="question-description">{{question.desc}}</span>
                                
                                <div class="flexV width">
                                    <!-- Validation Message -->
                                    <div 
                                        v-if="!valid"
                                        class="fS gray error"
                                    >{{ showValidationRule(question) }}</div>
                                    <label v-if="question.type === 'short-answer'">
                                        <textarea
                                            :name="'text_'+index"
                                            v-model="question.fillinAnswer"
                                            @change="changeAnswer($event, question.id)"
                                        />
                                    </label>
                                    <!-- Radio or Checkbox -->
                                    <label
                                        v-else
                                        class="check"
                                        v-for="(option, indexOption) in question.settings.options"
                                        :key="'option' + indexOption"
                                    >   
                                        <fragment v-if="question.type === 'multi-choice'">
                                            <input
                                                type="checkbox"
                                                :name="'checkbox_'+index"
                                                :value="option.value"
                                                v-model="question.fillinAnswer"
                                                @change="changeAnswer($event, question.id)"
                                                :disabled="checkCheckboxDisabled(question, option.value)"
                                            />
                                            <!-- checked -->
                                            <span 
                                                class="checkmark checkbox"
                                                :class="{ disabled: checkCheckboxDisabled(question, option.value)}"
                                            ></span>
                                        </fragment>
                                        <fragment v-else>
                                            <input
                                                type="radio"
                                                :name="'radio_'+index"
                                                :value="option.value"
                                                v-model="question.fillinAnswer"
                                                @change="changeAnswer($event, question.id)"
                                            />
                                            <!-- checked -->
                                            <span class="checkmark radio"></span>
                                        </fragment>
                                        <span class="text fM">{{ option.text }}</span>
                                    </label>
                                </div>
                            </ValidationProvider>
                        </div>
                    </ol>
                    <fragment v-if="isInitialized">
                        <div class="flexH width middle">
                            <a  
                                class="button rounded"
                                :class="{ 'disabled': isProcessing === true || invalid}"
                                @click.prevent="submit()"
                            >
                                <span class="fM">送出</span>
                            </a>
                        </div>
                        <div class="flexV width middle">
                            <span class="fXS center gray">
                                按一下送出按鈕，即表示<br>您確認已詳閱
                                <a 
                                    class="textButton third"
                                    href="https://storage.googleapis.com/tycard_assets/tycard_line/privacy_tyc.html"    
                                >隱私權政策</a>，並且同意
                                <a 
                                    class="textButton third"
                                    href="https://storage.googleapis.com/tycard_assets/tycard_line/privacy_tyc.html"
                                >使用條款</a>
                            </span>
                        </div>
                    </fragment>
                </ValidationObserver>
                <!-- <fragment v-else-if="canViewResult">
                    <ol>
                        <div
                            v-for="(question, index) in questions"
                            :key="'question' + index"
                        >
                            <li class="fXXL primary" id="question-title">{{question.title}}</li>
                            <span class="fM gray" id="question-description">{{question.desc}}</span>
                            <div class="flexV width">
                                <label
                                    class="check"
                                    v-for="(option, indexOption) in question.settings.options"
                                    :key="'option' + indexOption"
                                >
                                    <span class="text fM">{{ option.text }}</span>
                                    <span class="fM bold third">{{ displayStats(question.id, option.value) }}</span>
                                </label>
                            </div>
                        </div>
                    </ol>
                </fragment> -->
                <fragment v-else>
                    <ol>
                        <div
                            v-for="(question, index) in questions"
                            :key="'question' + index"
                        >
                            <li class="fXXL primary" id="question-title">{{question.title}}</li>
                            <span class="fM gray" id="question-description">{{question.desc}}</span>
                            <div class="flexV width">
                                <label v-if="question.type === 'short-answer'">
                                    <textarea
                                        :name="'text_'+index"
                                        :value="question.user.answer"
                                        :disabled="true"
                                    />
                                </label>
                                <!-- Radio or Checkbox -->
                                <label
                                    v-else
                                    class="check"
                                    v-for="(option, indexOption) in question.settings.options"
                                    :key="'option' + indexOption"
                                >   
                                    <fragment v-if="question.type === 'multi-choice'">
                                        <input
                                            type="checkbox"
                                            :name="'checkbox_'+index"
                                            :value="option.value"
                                            :disabled="true"
                                            :checked="checkUserAnswer(question, option.value)"
                                        />
                                        <!-- checked -->
                                        <span 
                                            class="checkmark checkbox"
                                            :class="{ disabled: true}"
                                        ></span>
                                    </fragment>
                                    <fragment v-else>
                                        <input
                                            type="radio"
                                            :name="'radio_'+index"
                                            :value="option.value"
                                            :disabled="true"
                                            :checked="checkUserAnswer(question, option.value)"
                                        />
                                        <!-- checked -->
                                        <span class="checkmark radio"
                                            :class="{ disabled: true}"
                                        ></span>
                                    </fragment>
                                    <span class="text fM">{{ option.text }}</span>
                                    <span v-if="canViewResult" class="fM bold third">{{ displayStats(question.id, option.value) }}</span>
                                </label>
                            </div>
                        </div>
                    </ol>
                </fragment>
            </section>
            
            <!-- Popup (作答完成) -->
            <section id="popCorrect" class="popCorrect popup activity_question">
                <div class="popBody">
                    <a class="close" @click.prevent="goBackTo">
                        <font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon>
                    </a>
                    <section class="popHeader">
                        <div class="img">
                            <img src="@/assets/icon/point-w.svg" />
                        </div>
                    </section>
                    <section class="popMain">
                        <span class="fM gray">恭喜您獲得</span>
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fXL">{{ recivePoints }}</span>
                        </div>
                        <span class="fS gray">來自</span>
                        <span class="fL gray">{{ title }}</span>
                    </section>
                    <section class="popFooter">
                        <div class="flexH width">
                            <a
                                class="flexV width center"
                                @click.prevent="$router.push('/record')"
                            >
                                <span class="fS gray">我的紅利桃子</span>
                                <div class="flexH">
                                    <div class="img">
                                        <img src="@/assets/icon/point.svg" />
                                    </div>
                                    <span class="fM bold">{{
                                        dipslayUserPointBalance()
                                    }}</span>
                                </div>
                            </a>
                            <div class="lineV"></div>
                            <a
                                class="flexV width center"
                                @click.prevent="$router.push('/notification')"
                            >
                                <span class="fS gray">通知中心</span>
                                <NotificationBell />
                            </a>
                        </div>
                    </section>
                </div>
            </section>

            <!-- Popup (作答完成無獎勵) -->
            <section
                id="popNoReward"
                class="popCorrect popup activity_question"
            >
                <div class="popBody">
                    <a class="close" @click.prevent="goBackTo">
                        <font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon>
                    </a>
                    <section class="popHeader">
                        <div class="img">
                            <img src="@/assets/icon/point-w.svg" />
                        </div>
                    </section>
                    <section class="popMain">
                        <span class="fXL">感謝參與回答</span>
                        <hr />
                        <!-- <span v-if="rewardCompleted" class="fM gray">獎勵已全數發完</span> -->
                        <!-- <span v-else class="fM gray">您已領取過獎勵</span> -->
                        <span class="fM gray">獎勵已全數發完</span>
                        <span class="fM gray">來參加看看其他活動吧！</span>
                    </section>
                    <!-- <section class="popFooter">
                    <div class="flexH width">
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/checkin.svg" />
                            </div>
                            <span class="fS">每日簽到</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/game.svg" />
                            </div>
                            <span class="fS">小遊戲</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/video.svg" />
                            </div>
                            <span class="fS">看影片</span>
                        </a>
                    </div>
                </section> -->
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import NotificationBell from "@/components/NotificationBell.vue";
import Spinner from '@/components/Spinner';
import { ValidationObserver, ValidationProvider} from 'vee-validate';

export default {
    name: "Questionnaire",
    components: {
        NotificationBell,
        Spinner,
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            questionInfo: {
                questions: []
            },
            answer: "",
            recivePoints: 0,
            getCampaignInfoUrl: "",
            campaignSubmitUrl: "",
            getCampaignStatsUrl: "",
            qtnContext: {},
            answers: {},
            totalVotes: {},
            campaigns: [],
            isParticipatedQRE: false,
            qnaCampaign: {},
            qnaCampaignId: "",
            stats: {},
            spin: true,
            isProcessing: false,
            isInitialized: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next();
    },
    created() {
        let vm = this;
        let that = vm;
        let campaignId = this.$route.params.id;

        if (!(that.user && that.user.userId)) {
            this.$router.push("/error");
        } else {
            that.getCampaigns()
                .then(() => {
                    that.qnaCampaign = that.campaigns.find(
                        (campaign) => campaign.campaignId == campaignId
                    );
                    console.log("qnaCampaign: ", that.qnaCampaign);

                    if (
                        !that.qnaCampaign ||
                        Object.keys(that.qnaCampaign).length == 0
                    ) {
                        this.$router.push(`/error`);
                        return;
                    }

                    that.isParticipatedQRE = that.qnaCampaign.hasCompleted;
                    that.spin = true;
                    that.campaignStart(campaignId)
                        .then((res) => {
                            that.getCampaignInfoUrl = res.getInfoUrl;
                            that.campaignSubmitUrl = res.submitUrl;
                            that.getCampaignStatsUrl = res.statsUrl;
                            that.qtnContext = res.context;
                            that.getQuestions()
                                .then((getQuestionsRes) => {
                                    Object.assign(
                                        that.questionInfo,
                                        getQuestionsRes
                                    );
                                    that.spin = false;
                                    that.isInitialized = true;
                                })
                                .catch((err) => {
                                    console.log("err: ", err);
                                    this.$router.push("/error");
                                });
                                
                            if (this.canParticipate || !this.canViewResult) return
                            
                            that.getCampaignStats()
                                .then((getStatsRes) => {
                                    this.stats = {...getStatsRes};
                                })
                                .catch((err) => {
                                    console.log("err: ", err);
                                    this.$router.push("/error");
                                });
                        })
                        .catch((err) => {
                            console.log("err: ", err);
                            this.$router.push("/error");
                        });
                })
                .catch((getCampaignsErr) => {
                    console.log("getCampaignsErr: ", getCampaignsErr);
                    this.$router.push("/error");
                });
        }
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        title() {
            return this.qnaCampaign?.campaignPrototype == 'vote' ? '線上投票': '民意調查';
        },
        questions() {
            if (this.questionInfo.questions.length) {
                const questions = this.questionInfo.questions.map(q => {
                    q.fillinAnswer = []
                    return q;
                });
                return questions;
            } else {
                return this.questionInfo.questions;
            }
        },
        rewardCompleted() {
            return this.qnaCampaign?.campaignBudget?.availablePoints <= 0;
        },
        isCampaignExpired() {
            return this.qnaCampaign.campaignEndTime * 1000 <= Date.now();
        },
        canParticipate() {
            return !this.isParticipatedQRE && !this.isCampaignExpired;  
        },
        canViewResult() {
            const {campaignPrototype, missions} = this.qnaCampaign;
            if (campaignPrototype === "vote" && this.isCampaignExpired) return true;
            if (
                campaignPrototype == "questionnaire" && 
                missions[0].settings.canViewResult &&
                (this.isParticipatedQRE || this.isCampaignExpired)
            ) return true;
            return false;
        }
    },
    watch: {
        stats() {
            Object.values(this.stats).forEach(
                (question) => {
                    if (question.type === 'short-answer') return;
                    this.totalVotes[question.id] = question.answers.reduce(
                        (sum, curr) => curr.answer != null ? sum += curr.count : sum
                        , 0
                    )
                }
            )
        }
    },
    methods: {
        dipslayUserPointBalance() {
            return this.userPoints.pointBalance;
        },
        changeAnswer(_, questionId) {
            this.answers[questionId] = this.questions.find(q => q.id === questionId).fillinAnswer;
            this.answers = { ...this.answers };
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        getCampaignAPI() {
            const publisher = this.$route.meta.publisher;
            const param = publisher ? `&publisherType=${publisher}` : '';
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true&includeInactive=true${param}`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    .then((res) => {
                        this.campaigns = res.data.campaigns;
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
            );
        },
        campaignStart(campaignId) {
            var config = {
                method: "get",
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${campaignId}/run?uuid=${this.user.userId}`,
                headers: {}
            };
            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getQuestions() {
            let config = {
                url: this.getCampaignInfoUrl,
                method: "GET",
                params: {
                    uuid: this.user.userId
                }
            };
            return this.$http(config)
                .then((res) => {
                    console.log("questions: ", res);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err: ", err);
                });
        },
        submit() {
            if (this.isProcessing === true)
                return;
            this.isProcessing = true;

            var data = JSON.stringify({
                uuid: this.user.userId,
                answers: this.answers,
                evaluateImmediately: true,
                context: this.qtnContext
            });

            var config = {
                method: "post",
                url: this.campaignSubmitUrl,
                headers: {
                    "Content-Type": "application/json"
                },
                data: data
            };

            var self = this;
            return this.$http(config)
                .then(
                    function (response) {
                        console.log("submit: ", response.data);

                        this.parseRewards(response.data);

                        this.getUserPoints()
                            .then((getUserPointsRes) => {
                                this.$store.commit(
                                    "updateUserPoints",
                                    getUserPointsRes
                                );
                                this.doActionByClaimRewardType(
                                    response.data
                                );
                            })
                            .catch((err) => {
                                console.log("err: ", err);
                                this.errorHandler();
                            });

                        return response.data;
                    }.bind(this)
                )
                .catch(function (error) {
                    console.log('submitErr', error.response.data);
                    // self.errorHandler();
                    // 不應該直接到error page，要看是什麼錯誤
                    // 通常是 已作答過 
                    self.noRewardAction();
                })
        },
        doActionByClaimRewardType(responseData = {}) {
            let reward =
                responseData.claimRewardResults?.length
                    ? responseData.claimRewardResults[0]
                    : [];
            let status = reward.status;
            if (status) {
                this.correctAction();
            } else {
                this.noRewardAction();
            }
        },
        correctAction() {
            var popCorrect = document.getElementById("popCorrect");
            popCorrect.style.display = "flex";
        },
        noRewardAction() {
            var popWrong = document.getElementById("popNoReward");
            popWrong.style.display = "flex";
        },
        getUserPoints() {
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        parseRewards(responseData = {}) {
            let reward =
                responseData.claimRewardResults?.length
                    ? responseData.claimRewardResults[0]
                    : [];
            let status = reward.status;
            if (status) {
                this.recivePoints =
                    reward.data?.points ? reward.data.points : 0;
            } else {
                this.recivePoints = 0;
            }
        },
        getCampaignStats() {
            var config = {
                method: "get",
                url: this.getCampaignStatsUrl,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        displayStats(questionId, optionValue) {
            const answer = this.stats[questionId]?.answers.find(({answer}) => answer == optionValue);
            if (this.qnaCampaign.campaignPrototype === 'vote') {
                const votes = answer?.count || 0;
                return votes + '票';
            } else {
                const VotesPercentage = Math.round((answer?.count / this.totalVotes[questionId]) * 100) || 0;
                return VotesPercentage + '%';
            }
        },
        errorHandler() {
            this.$router.push('/error')
            // window.location.reload();
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        goBackTo() {
            const status = this.isCampaignExpired ? 'inactive' : 'active';
            this.qnaCampaign.campaignPrototype == 'vote'
                ? this.$router.push(`/service/e-voting/${status}`)
                : this.$router.push(`/service/survey/${status}`);
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD HH:mm:ss");
        },
        getValidationRules(question) {
            const {settings, type} = question;
            if (type === 'short-answer' && !settings.required) return '';
            if (type === 'multi-choice') {
                const min = settings.minSelection || 1;
                const max = settings.maxSelection || settings.options.length;
                return `required|minmax:${min},${max}`
            }
            return 'required';
        },
        showValidationRule(question) {
            const {settings, type} = question;
            if (type === 'short-answer' && !settings.required) return;
            if (type === 'multi-choice') {
                const min = settings.minSelection || 1;
                const max = settings.maxSelection;
                return `(最少須選取${min}個${max ? `，最多可選取${max}個`: ''})`
            }
            return '(必填)'
        },
        checkCheckboxDisabled(question, optionValue) {
            const {type, fillinAnswer, settings} = question;
            if (type !== 'multi-choice' || !settings.maxSelection) return;
            return fillinAnswer.length >= settings.maxSelection && fillinAnswer.indexOf(optionValue) === -1;
        },
        checkUserAnswer (question, optionValue) {
            let checked = false;
            if (question.userAnswers) {
                for (let i=0, len = question.userAnswers.length; i<len; i++) {
                    if (question.userAnswers[i].answer === optionValue) {
                        checked = true;
                        break; 
                    }
                    
                }
            }
           return checked;

        }
    }
};
</script>
