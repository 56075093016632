<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <Spinner :spin="spin"/>
            <section class="header activity_question">
                <a class="icon iconButton third" @click.prevent="goBackTo">
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
                <span class="fL">{{ title }}</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section  class="main activity_question">
            <div class="flex item-center">
                <div style="width: 180px;">問卷進度： {{ answeredQuestion.length }} / {{ questions.length }}</div>
                <ProgressBar :progress="((answeredQuestion.length / questions.length) * 100)"/>
            </div>
                <ValidationObserver v-if="!qnaCampaign.hasCompleted" v-slot="{ invalid }">
                    <ol>
                        <div
                            v-for="(question, index) in questions"
                            :key="'question' + index"
                        >
                            <ValidationProvider
                                slim 
                                :rules="getValidationRules(question)" 
                                v-slot="{ valid }"
                            >
                                <li class="fXXL primary" id="question-title">{{index + 1}}. {{question.title}}</li>
                                <!-- <span class="fM gray" id="question-description">{{question.desc}}</span> -->
                                <div 
                                    v-if="!valid"
                                    class="fS gray error"
                                >{{ showValidationRule(question) }}</div> 
                                <div class="flexV w-full" >
                                    <!-- Radio -->
                                    <label
                                        class="check gap-4"
                                        :class="{active: (question.fillinAnswer === option.value || (question.fillinAnswer && question.fillinAnswer.includes(option.value))), correct: submited && question.fillinAnswer === option.value && question.expectedAnswer === option.value && !question.answerChanged, wrong: submited && question.fillinAnswer === option.value && question.expectedAnswer !== option.value && !question.answerChanged}" 
                                        v-for="(option, indexOption) in question.options"
                                        :key="'option' + indexOption"

                                    >   
                                        <input
                                            v-if="question.type === 'multi-choice'"
                                            type="checkbox"
                                            :name="'checkbox_'+index"
                                            :value="option.value"
                                            v-model="question.fillinAnswer"
                                            hidden
                                            :disabled="checkCheckboxDisabled(question, option.value)"
                                            @change="setAnsweredQuestion({...question, idx: index})"
                                        />
                                        <input
                                            v-else-if="question.type === 'single-choice'"
                                            type="radio"
                                            :name="'radio_'+index"
                                            hidden
                                            :value="option.value"
                                            v-model="question.fillinAnswer"
                                            :disabled="checkCheckboxDisabled(question, option.value)"
                                        />
                                        <!-- checked -->
                                        <span 
                                            v-if="question.type === 'multi-choice'"
                                            class="checkmark checkbox"
                                            :class="{ disabled: checkCheckboxDisabled(question, option.value)}"
                                        ></span>
                                        <span v-else class="checkmark radio"></span>
                                        <span class="text fL">{{ option.text }}</span>
                                        <font-awesome-icon :icon="['fas', 'check']" style="width: 20px; height: 20px;" v-if="submited && question.fillinAnswer === option.value && question.expectedAnswer === option.value && !question.answerChanged" />
                                        <font-awesome-icon :icon="['fas', 'xmark']" style="width: 20px; height: 20px;" v-if="submited && question.fillinAnswer === option.value && question.expectedAnswer !== option.value && !question.answerChanged" />
                                    </label>

                                </div>
                            </ValidationProvider>
                            <hr v-if="index !== questions.length-1" class="my-2" style="border: 1px solid #eeeeee;">
                        </div>
                    </ol>

                    <div class="flexH width middle">
                        <!-- <a class="button rounded gray" onclick="popWrong()">
                            <span class="fM">上一頁</span>
                        </a> -->
                        <a  
                            v-if="isInitialized"
                            class="button rounded w-full"
                            :class="{ 'disabled': (isProcessing || invalid) || (submited && questions.every(q => !q.answerChanged)) }"
                            @click.prevent="openSubmitPop()"
                        >
                            <span class="fM">送出</span>
                        </a>
                    </div>
                </ValidationObserver>
                <fragment v-else>
                    <ul>
                        <div
                            v-for="(question, index) in questions"
                            :key="'question' + index"
                        >
                            <li class="fXXL primary" id="question-title">{{question.title}}</li>
                            <span class="fM gray" id="question-description">{{question.desc}}</span>
                            <div class="flexV" >
                                <!-- Radio -->
                                <label
                                    class="check"
                                    v-for="(option, indexOption) in question.settings.options"
                                    :key="'option' + indexOption"
                                >   
                                    <fragment v-if="question.type === 'multi-choice'">
                                        <input
                                            type="checkbox"
                                            :name="'checkbox_'+index"
                                            :value="option.value"
                                            disabled
                                            :checked="checkUserAnswer(question, option.value)"
                                        />
                                        <!-- checked -->
                                        <span class="checkmark checkbox disabled"></span>
                                    </fragment>
                                    <fragment v-else>
                                        <input
                                            type="radio"
                                            :name="'radio_'+index"
                                            :value="option.value"
                                            disabled
                                            :checked="checkUserAnswer(question, option.value)"
                                        />
                                        <!-- checked -->
                                        <span class="checkmark radio disabled"></span>
                                    </fragment>
                                    <span class="text fL">{{ option.text }}</span>
                                </label>
                            </div>
                        </div>
                    </ul>
                    <div class="flexH width middle" v-if="publisher !== 'municipal'">
                        <a  
                            class="button rounded"
                            @click.prevent="$router.push('/campaign')"
                        >
                            <span class="fM">返回專屬任務</span>
                        </a>
                    </div>
                </fragment>
            </section>
            <!-- Popup (答案錯誤) -->
            <section id="popWrong" class="popup">
                <div class="popBody">
                    <a class="close" @click.prevent="againButton()"
                        ><font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon
                    ></a>
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <div class="icon">
                            <span class="fL">答案錯誤</span>
                        </div>
                        <div class="fM text-center mt-2">答案不對欸～再試試看吧！</div>
                    </section>
                    <section class="popFooter">
                        <a
                            class="button submit rounded"
                            @click.prevent="againButton()"
                            ><span class="fM">確認</span></a
                        >
                    </section>
                </div>
            </section>
            <!-- Popup (答案正確) -->
            <section id="popCorrect" class="popCorrect popup">
                <div class="popBody">
                    <a class="close" @click.prevent="closePopCorrect()"
                        ><font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon
                    ></a>
                    <section class="popMain">
                        <div class="icon">
                            <font-awesome-icon :icon="['far', 'circle-check']" style="width: 60px;height: 60px;" />
                        </div>
                        <div class="fL text-center">{{$route.fullPath.includes('b1') ? '感謝您填寫問眷' : '通關成功'}}</div>
                        <div class="fM text-center mt-2">{{$route.fullPath.includes('b1') ? '恭喜您獲得雲林幣 10 枚！' : '答案全對！您是民間高手，感謝您的作答，恭喜您獲得雲林幣 5 枚！'}}</div>
                    </section>
                    <section class="popFooter">
                        <a href="" class="button  rounded text-primary background border-button">前往雲林幣錢包</a>
                        <a
                            class="button submit rounded"
                            @click.prevent="closePopCorrect()"
                            ><span class="fM">確認</span></a
                        >
                    </section>
                </div>
            </section>

            <section id="popLeave" class="popup">
                <div class="popBody">
                    <a class="close" @click.prevent="closeLeavePop()"
                        ><font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon
                    ></a>
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <div class="fL text-center">提示</div>
                        <div class="icon">
                        </div>
                        <div class="text-center">您確定要中途離開嗎？</div>
                    </section>
                    <section class="popFooter">
                        <a class="button  rounded text-primary background border-button" @click.prevent="closeLeavePop()">返回</a>

                        <a
                            class="button submit rounded"
                            @click.prevent="closeLeavePop()"
                            ><span class="fM">確認</span></a
                        >
                    </section>
                </div>
            </section>

            <section id="popSubmit" class="popup">
                <div class="popBody">
                    <a class="close" @click.prevent="closeSubmitPop()"
                        ><font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon
                    ></a>
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <div class="fL text-center">提示</div>
                        <div class="icon">
                        </div>
                        <div class="text-center">送出就會扣除一次機會，請問您確定要送出了嗎？</div>
                    </section>
                    <section class="popFooter">
                        <a class="button  rounded text-primary background border-button" @click.prevent="closeSubmitPop()">再想想</a>

                        <a
                            class="button submit rounded"
                            @click.prevent="submit()"
                            ><span class="fM">送出</span></a
                        >
                    </section>
                </div>
            </section>

            <!-- Popup (答案正確無獎勵) -->
            <section
                id="popNoReward"
                class="popCorrect popup activity_question"
            >
                <div class="popBody">
                    <a class="close" @click.prevent="goBackTo">
                        <font-awesome-icon
                            icon="fas fa-times"
                        ></font-awesome-icon>
                    </a>
                    <section class="popHeader">
                        <div class="img">
                            <img src="@/assets/icon/point-w.svg" />
                        </div>
                    </section>
                    <section class="popMain">
                        <span class="fXL">感謝參與回答</span>
                        <hr />
                        <!-- <span v-if="rewardCompleted" class="fM gray">獎勵已全數發完</span> -->
                        <!-- <span v-else class="fM gray">您已領取過獎勵</span> -->
                        <span class="fM gray">獎勵已全數發完</span>
                        <span class="fM gray">來參加看看其他活動吧！</span>
                    </section>
                    <!-- <section class="popFooter">
                    <div class="flexH width">
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/checkin.svg" />
                            </div>
                            <span class="fS">每日簽到</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/game.svg" />
                            </div>
                            <span class="fS">小遊戲</span>
                        </a>
                        <div class="lineV"></div>
                        <a class="flexV center width" @click.prevent="$router.push('/home')">
                            <div class="img">
                                <img src="@/assets/icon/video.svg" />
                            </div>
                            <span class="fS">看影片</span>
                        </a>
                    </div>
                </section> -->
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
import Spinner from '@/components/Spinner';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
    name: "QA",
    components: {
        Spinner,
        ValidationObserver,
        ValidationProvider,
        ProgressBar
    },
    data() {
        return {
            submited: false,
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            questionInfo: {
                questions: []
            },
            answer: "",
            prize: 10,
            recivePoints: 0,
            getCampaignInfoUrl: "",
            campaignSubmitUrl: "",
            qtnContext: {},
            answers: [],
            campaigns: [],
            countUnread: 0,
            isParticipatedQA: false,
            qnaCampaign: {},
            qnaCampaignId: "",
            spin: true,
            isProcessing: false,
            isInitialized: false,
            publisher: this.$route.meta.publisher,
            answeredQuestion: []
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     // called before the route that renders this component is confirmed.
    //     // does NOT have access to `this` component instance,
    //     // because it has not been created yet when this guard is called!
    //     console.log(to, from);
    //     next();
    // },
    created() {
        let vm = this;
        let that = vm;
        let campaignId = this.$route.params.id;

        // if (!(that.user && that.user.userId)) {
        //     this.$router.push("/error");
        // } else {
            that.getCampaigns()
                .then(() => {
                    that.qnaCampaign = that.campaigns.find(
                        (campaign) => campaign.campaignId == campaignId
                    );
                    that.qnaCampaign.missions.forEach((mission) => {
                        mission.settings.questions.forEach(q => {
                            q.fillinAnswer = []
                        })
                    })
                    console.log("qnaCampaign: ", that.qnaCampaign);
                    // if (
                    //     that.qnaCampaign &&
                    //     that.checkIsParticipatedQA(that.qnaCampaign)
                    // ) {
                    //     this.$store.commit("updateIsFinishQuestions", true);
                    //     this.$router.push(`/home`);
                    //     return;
                    // }
                    if (
                        !that.qnaCampaign ||
                        Object.keys(that.qnaCampaign).length == 0
                    ) {
                        // this.$store.commit("updateIsQANotExist", true);
                        this.$router.push(`/error`);
                        return;
                    }
                    that.spin = true;
                    that.campaignStart(campaignId)
                        .then((res) => {
                            that.getCampaignInfoUrl = res.getInfoUrl;
                            that.campaignSubmitUrl = res.submitUrl;
                            that.qtnContext = res.context;
                            that.getQuestions()
                                .then((getQuestionsRes) => {
                                    Object.assign(
                                        that.questionInfo,
                                        getQuestionsRes
                                    );
                                    that.spin = false;
                                    that.isInitialized = true;
                                })
                                .catch((err) => {
                                    console.log("err: ", err);
                                    this.$router.push("/error");
                                });
                        })
                        .catch((err) => {
                            console.log("err: ", err);
                            this.$router.push("/error");
                        });
                })
                .catch((getCampaignsErr) => {
                    console.log("getCampaignsErr: ", getCampaignsErr);
                    this.$router.push("/error");
                });
        // }
    },
    mounted() {},
    computed: {
        ...mapState(["user", "userInfo", "isQANotExist"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        questions() {
            return this.qnaCampaign?.missions && this.qnaCampaign?.missions[0]?.settings?.questions
        },
        rewardCompleted() {
            return (this.qnaCampaign 
                && this.qnaCampaign.campaignBudget 
                && this.qnaCampaign.campaignBudget.availablePoints <= 0);
        },
        title() {
            return '問答拿獎勵'
        },
    },
    methods: {
        setAnsweredQuestion(q) {
            if (q.fillinAnswer.length > 0) {
                const idx = this.answeredQuestion.indexOf(q.idx)
                if (idx === -1) {
                    this.answeredQuestion.push(q.idx)
                }
            } else {
                const idx = this.answeredQuestion.indexOf(q.idx)
                if (idx > -1) {
                    this.answeredQuestion.splice(idx, 1)
                }
            }
        },
        openSubmitPop() {
            const popSubmit = document.getElementById("popSubmit");
            popSubmit.style.display = "flex";
        },
        closeSubmitPop() {
            const popSubmit = document.getElementById("popSubmit");
            popSubmit.style.display = "none";
        },
        openLeavePop() {
            const popLeave = document.getElementById("popLeave");
            popLeave.style.display = "flex";
        },
        closeLeavePop() {
            const popLeave = document.getElementById("popLeave");
            popLeave.style.display = "none";
        },
        handleOptionClick(question, option) {
            if (question.type === 'multi-choice') {
                this.answers.push(option)
                if (!question.fillinAnswer) {
                    question.fillinAnswer = []
                }
                const idx = question.fillinAnswer.indexOf(option)
                if (idx > -1) {
                    question.fillinAnswer.splice(idx, 1)
                } else {
                    question.fillinAnswer.push(option)
                }
            } else {
                question.answerChanged = true
            }
        },
        dipslayUserPointBalance() {
            return this.userPoints.pointBalance;
        },
        changeAnswer(_, questionId) {
            this.answers[questionId] = this.questions.find(q => q.id == questionId)?.fillinAnswer;
            this.answers = { ...this.answers };
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        dummyGetCampaigns() {
            let result = {
                uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
                memberId: 208,
                campaigns: [
                    {
                        campaignId: "M_RdROw7eY5B",
                        campaignName: "測試幸運輪盤任務",
                        campaignDesc: "輪盤抽獎。Yay!!",
                        campaignStatus: "approved",
                        campaignPrototype: "minigame",
                        campaignStartTime: 1652284800,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652322816,
                        missions: [
                            {
                                missionId: 44,
                                missionName: "幸運輪盤（小遊戲任務）",
                                missionDesc: "As title",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["38"]
                                        },
                                        status: "completed",
                                        completedAt: 1652341991
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_r5PDKD0m58",
                        campaignName: "PrePOB測試任務：抽獎小遊戲",
                        campaignDesc: "As title",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652112000,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652167000,
                        missions: [
                            {
                                missionId: 36,
                                missionName: "抽獎小遊戲",
                                missionDesc: "好禮大方送",
                                participation: [
                                    {
                                        data: {
                                            rewardIds: ["31"]
                                        },
                                        status: "completed",
                                        completedAt: 1652170978
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_xd4wXAwvkj",
                        campaignName: "PrePOB測試任務：推薦",
                        campaignDesc:
                            "To recommend or not to recommend, that is the question.",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1652025600,
                        campaignEndTime: 1656604799,
                        campaignCreatedAt: 1652070487,
                        missions: [
                            {
                                missionId: 31,
                                missionName: "測試推薦任務 之 推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1652092171
                                    },
                                    {
                                        status: "completed",
                                        completedAt: 1652092477
                                    }
                                ]
                            },
                            {
                                missionId: 32,
                                missionName: "推薦人關卡",
                                missionDesc: "推薦他人成功可以領取獎項"
                            },
                            {
                                missionId: 34,
                                missionName: "被推薦人關卡",
                                missionDesc:
                                    "接受他人推薦連結並成功註冊者可以領取獎項"
                            },
                            {
                                missionId: 35,
                                missionName: "註冊關卡",
                                missionDesc: "成功註冊者可以領取獎項"
                            }
                        ]
                    },
                    {
                        campaignId: "M_p5O76qR854",
                        campaignName: "註冊任務",
                        campaignDesc: "成功註冊桃園市民卡即可獲得100點",
                        campaignStatus: "approved",
                        campaignPrototype: null,
                        campaignStartTime: 1651132800,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1651129632,
                        missions: [
                            {
                                missionId: 22,
                                missionName: "會員註冊任務",
                                missionDesc:
                                    "只要成功註冊會員即可獲得，每人限領一次",
                                participation: [
                                    {
                                        data: {},
                                        status: "completed",
                                        completedAt: 1652253394
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        campaignId: "M_qkwQ9ygK5L",
                        campaignName: "2022問答任務debug測試",
                        campaignDesc: "答對有獎，限量三名",
                        campaignStatus: "approved",
                        campaignPrototype: "test",
                        campaignStartTime: 1650816000,
                        campaignEndTime: 1656575999,
                        campaignCreatedAt: 1650862201,
                        missions: [
                            {
                                missionId: 16,
                                missionName: "問答任務 測試",
                                missionDesc: "有獎問答 測試",
                                participation: [
                                    {
                                        status: "completed",
                                        completedAt: 1651387810
                                    }
                                ]
                            }
                        ]
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        getCampaignAPI() {
            const param = `&publisherType=${this.publisher === 'municipal' ? 'municipal' : 'exclusive'}`;
            let config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true${param}`,
                method: "GET"
            };
            return this.$http(config);
        },
        getCampaigns() {
            return (
                this.getCampaignAPI()
                    // this.campaignDummy()
                    .then((res) => {
                        this.campaigns = res.data.campaigns;
                        console.log("campaigns: ", this.campaigns);
                        return res;
                    })
            );
        },
        campaignStart(campaignId) {
            const config = {
                method: "get",
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/campaigns/${campaignId}/run?uuid=${this.user.userId}`,
                headers: {}
            };
            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getQuestions() {
            let config = {
                url: this.getCampaignInfoUrl,
                method: "GET",
                params: {
                    uuid: this.user.userId
                }
            };
            return this.$http(config)
                .then((res) => {
                    console.log("questions: ", res);
                    return res.data;
                })
                .catch((err) => {
                    console.log("err: ", err);
                });
        },
        dummyWrongAnswer() {
            let result = {
                success: false,
                evaluation: {
                    corrects: [],
                    incorrects: [
                        {
                            id: 10,
                            prompted: "A",
                            expected: "C"
                        }
                    ],
                    score: 0,
                    totalScore: 1,
                    passingScore: 1,
                    hasPassed: false,
                    correctness: 0
                }
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        dummyCorrectAnswer() {
            let result = {
                success: true,
                evaluation: {
                    corrects: [
                        {
                            id: 10,
                            prompted: "C",
                            expected: "C"
                        }
                    ],
                    incorrects: [],
                    score: 1,
                    totalScore: 1,
                    passingScore: 1,
                    hasPassed: true,
                    correctness: 1
                },
                claimRewardResults: [
                    {
                        merchantId: "tycard2022",
                        campaignId: "M_PkJYrY4mbM",
                        missionId: 14,
                        memberId: 208,
                        uuid: "line.U3ff10b3a06117cd203d0292637c34de3",
                        requests: {
                            12: false
                        },
                        rewardId: 12,
                        rewardName: "問答任務完成獎品100點",
                        campaignBudgetId: 16,
                        context: {
                            name: "點數",
                            amount: 100
                        },
                        status: true,
                        data: {
                            points: 100,
                            transactionId:
                                "47f28c3ac01dfbbf535c6bb58f89cf96c7ce4a3d",
                            transactionStatus: "success",
                            transactionType: "Activity-Reward"
                        }
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        dummyNoReward() {
            let result = {
                success: true,
                evaluation: {
                    corrects: [
                        {
                            id: 10,
                            prompted: "C",
                            expected: "C"
                        }
                    ],
                    incorrects: [],
                    score: 1,
                    totalScore: 1,
                    passingScore: 1,
                    hasPassed: true,
                    correctness: 1
                },
                claimRewardResults: [
                    {
                        status: false,
                        rewardId: 12,
                        rewardName: "問答任務完成獎品100點",
                        campaignBudgetId: 16,
                        context: {
                            name: "點數",
                            amount: 100
                        },
                        error: {
                            code: "RW105",
                            message: "獎項不允許重複領取"
                        }
                    }
                ]
            };
            let promise = new Promise((resolve) => {
                resolve({ data: result });
            });
            return promise;
        },
        async submit() {
            if (this.isProcessing === true)
                return;
            this.isProcessing = true;
            this.submited = true
            const questions = this.qnaCampaign?.missions && this.qnaCampaign?.missions[0]?.settings?.questions
            questions.forEach(q => {
                q.answerChanged = false
            })
            this.isProcessing = false;
            // const isCorrect = questions.every(q => q.fillinAnswer === q.expectedAnswer) || this.$route.fullPath.includes('b1')
            // if (isCorrect) {
            //     await this.$http({
            //         url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/callback/missions/complete/${this.qnaCampaign.missions[0].id}`,
            //         method: "POST",
            //         data: {

            //         }
            //     })
            //     this.correctAction()
            // } else {
            //     this.wrongAction()
            // }
            // this.closeSubmitPop()
            let answers = {}
            questions.forEach(q => {
                answers[q.idx] = q.fillinAnswer
            })

            const data = JSON.stringify({
                uuid: this.user.userId,
                member_id: this.userInfo.memberId,
                nonce: 22222,
                data: answers
                // answers: this.answers,
                // evaluateImmediately: true,
                // context: this.qtnContext
            });

            const config = {
                url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/callback/missions/complete/${this.qnaCampaign.missions[0].id}`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: data
            };

            // return this.dummyNoReward(config)
            const self = this;
            return this.$http(config)
                .then(
                    function (response) {
                        console.log("submit: ", response.data);
                        if (this.checkIsCorrect(response.data)) {
                            this.parseRewards(response.data);
                            console.log("Correct!");
                            this.getUserPoints()
                                .then((getUserPointsRes) => {
                                    this.$store.commit(
                                        "updateUserPoints",
                                        getUserPointsRes
                                    );
                                    this.doActionByClaimRewardType(
                                        response.data
                                    );
                                })
                                .catch((err) => {
                                    console.log("err: ", err);
                                    this.errorHandler();
                                });
                        } else {
                            console.log("Wrong!");
                            this.wrongAction();
                            this.isProcessing = false;
                        }
                        return response.data;
                    }.bind(this)
                )
                .catch(function (error) {
                    console.log(error.response.data);
                    const code = error.response.data.code;
                    if (code == 'QTN101') { //已成功作答過
                        self.noRewardAction();
                    }
                    else if (code == 'QTN102') { //答案有誤
                        self.wrongAction();
                        self.isProcessing = false;
                    }
                    // self.errorHandler();
                })
        },
        doActionByClaimRewardType(responseData = {}) {
            let reward =
                responseData.claimRewardResults &&
                responseData.claimRewardResults.length > 0
                    ? responseData.claimRewardResults[0]
                    : [];
            let status = reward.status;
            if (status) {
                this.correctAction();
            } else {
                this.noRewardAction();
            }
        },
        correctAction() {
            const popCorrect = document.getElementById("popCorrect");
            popCorrect.style.display = "flex";
        },
        closePopCorrect() {
            const popCorrect = document.getElementById("popCorrect");
            popCorrect.style.display = "none";
            this.$router.push('/service/questions')
        },
        wrongAction() {
            const popWrong = document.getElementById("popWrong");
            popWrong.style.display = "flex";
        },
        noRewardAction() {
            const popWrong = document.getElementById("popNoReward");
            popWrong.style.display = "flex";
        },
        againButton() {
            const popWrong = document.getElementById("popWrong");
            popWrong.style.display = "none";
        },

        getUserPoints() {
            const config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        parseRewards(responseData = {}) {
            let reward =
                responseData.claimRewardResults &&
                responseData.claimRewardResults.length > 0
                    ? responseData.claimRewardResults[0]
                    : [];
            let status = reward.status;
            if (status) {
                this.recivePoints =
                    reward.data && reward.data.points ? reward.data.points : 0;
            } else {
                this.recivePoints = 0;
            }
        },
        checkIsCorrect(responseData = {}) {
            let hasPassed = responseData.evaluation.hasPassed;
            return hasPassed;
        },
        checkIsParticipatedQA(campaign) {
            let mission =
                campaign.missions && campaign.missions.length > 0
                    ? campaign.missions[0]
                    : {};
            if (Object.keys(mission).length > 0) {
                let participation = mission.participation
                    ? mission.participation[0]
                    : {};
                if (participation.status === "completed") {
                    return true;
                }
            }
            return false;
        },
        errorHandler() {
            window.location.reload();
        },
        goBackTo() {
            this.openLeavePop()
            // this.publisher == 'municipal'
            //     ? this.$router.push("/service/questions")
            //     : this.$router.push("/campaign");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY/MM/DD HH:mm:ss");
        },
        getValidationRules() {
            return 'required';
        },
        showValidationRule() {
            return '(必填)'
        },
        checkCheckboxDisabled(question, optionValue) {
            const {type, fillinAnswer, maxSelection} = question;
            if (type !== 'multi-choice' || !maxSelection) return (this.submited && fillinAnswer === question.expectedAnswer && !question.answerChanged);
            return (fillinAnswer.length >= maxSelection && fillinAnswer.indexOf(optionValue) === -1);
        },
        checkUserAnswer(question, optionValue) {
            let checked = false;
            if (question.userAnswers) {
                for (let i = 0, len = question.userAnswers.length; i < len; i++) {
                    if (question.userAnswers[i].answer === optionValue) {
                        checked = true;
                        break; 
                    } 
                }
            }
           return checked;
        }
    }
};
</script>
<style lang="scss">
ol {
   padding: 0!important;
}
li::marker {
    content: ''
}
.check {
    background: #f6f2fd;
    color: 2b2455;
    margin: 10px 0;
    padding: 15px !important;
    &.active {
    background: #a480ff!important;
    span {
        color: #fff!important;
    }
}
    &.correct {
        background: #FFC402!important;
    }
    &.wrong {
        background: #BDBDBD!important;
    }
}
</style>