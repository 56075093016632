<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  height: 15px;
  background-color: var(--cOther1);
  border-radius: 15px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: var(--c1);
  transition: width 0.5s ease;
}
</style>